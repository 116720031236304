<template>
	<van-tabbar v-model="activeIndex" :fixed="true" :placeholder="true">
		<van-tabbar-item to="/">
			<div class="ico ico-1"></div>
			<div class="item-label">{{$t('首页')}}</div>
		</van-tabbar-item>
		<van-tabbar-item to="/trend">
			<div class="ico ico-2"></div>
			<div class="item-label">{{$t('行情')}}</div>
		</van-tabbar-item>
		<!-- <van-tabbar-item @click="goUrl">
			<div class="ico ico-5"></div>
			<div class="item-label">{{$t('外汇')}}</div>
		</van-tabbar-item> -->
		<van-tabbar-item to="/team">
			<div class="ico ico-3"></div>
			<div class="item-label">{{$t('团队')}}</div>
		</van-tabbar-item>
		<van-tabbar-item to="/my">
			<div class="ico ico-4"></div>
			<div class="item-label">{{$t('资产')}}</div>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
import helper from "@/utils/helper";
	export default {
		name: 'Foot',
		props: {
			active: {
				type: Number,
				default: 0
			}
		},
		computed: {
			activeIndex: {
				get: function () {
					return this.active;
				},
				set: function () {
				}
			}
		},
		data: function() {
			return {}
		},
		methods: {
			goUrl () {
				let token = helper.getToken();
				window.location.href = 'https://wh.airobottoday.xyz/#/login?token=' + token;
			}
		}
	}
</script>
<style>
	.van-tabbar{
		background-color: #1a2331!important;
		border-radius: 20px 20px 0 0;
	}
	.van-tabbar::after{
		display: none;
	}
	.van-tabbar-item--active{
		background-color: transparent!important;
	}
	.ico{
		display: inline-block;
		width: 23px;
		height: 23px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
	.ico-1{
		background-image: url(../assets/pictures/foot1.png);
	}
	.ico-2{
		background-image: url(../assets/pictures/foot2.png);
	}
	.ico-3{
		background-image: url(../assets/pictures/foot3.png);
	}
	.ico-4{
		background-image: url(../assets/pictures/foot4.png);
	}
	.ico-5{
		background-image: url(../assets/pictures/foot5.png);
	}
	.van-tabbar-item__icon{
		display: none;
	}
	.van-tabbar,
	.van-tabbar__placeholder{
		height: 66px!important;
	}
	.van-tabbar-item--active .ico-1{
		background-image: url(../assets/pictures/foot1_on.png);
	}
	.van-tabbar-item--active .ico-2{
		background-image: url(../assets/pictures/foot2_on.png);
	}
	.van-tabbar-item--active .ico-3{
		background-image: url(../assets/pictures/foot3_on.png);
	}
	.van-tabbar-item--active .ico-4{
		background-image: url(../assets/pictures/foot4_on.png);
	}
	.van-tabbar-item--active .ico-5{
		background-image: url(../assets/pictures/foot5_on.png);
	}
	.item-label{
		margin-top: 5px;
		color: #8a92b2;
	}
	.van-tabbar-item--active .item-label{
		color: #326ce2;
	}



</style>