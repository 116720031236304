<template>
    <div id="app">
        <router-view/>
        <template v-if="is_show">
            <Service/>
        </template>
    </div>
</template>

<script>
    import Service from "./components/Service";

    export default {
        name: 'App',
        components: {Service},
        data() {
            return {
                is_show: true
            }
        },
        mounted() {
            // console.log(window.location);
            console.log(this.$route.name);
            let str = window.location.hash.substring(0,10);
            if (this.$route.name == 're-error' || this.$route.name == 're-page' || str == '#/re-page?' || str == '#/re-error') {
                this.is_show = false;
            }
        }
    }
</script>