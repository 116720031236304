<template>
    <div class="index-page">
        <div class="index-top">
            <div class="index-header">
                <div class="logo-cell">
                    <img src="../../assets/pictures/logo.png" >
                    <div class="wel-label">{{$t('Hello')}}，{{user.name}}</div>
                </div>
                <div class="top-link flex-center">
                    <!-- <router-link to="/christmas" v-if="user.sd_flag == 1">
                        <div class="christ-a">
                            <img src="../../assets/pics/christmas/lw.png" style="width: 40px;height: 40px;margin-right: 10px">
                        </div>
                    </router-link> -->
                    <div class="btn-circle" @click="url('/message-list')">
                        <van-icon :name="require('../../assets/pictures/xitongxiaoxi.png')" :badge="m_count == 0 ? '': (m_count > 99 ? '99+' : m_count)" />
                    </div>
                    <router-link to="/lang" class="btn-circle" v-if="select_lang > 0">
                        <span class="iconfont icon-yuyan"></span>
                    </router-link>
                </div>
            </div>
            <div class="index-sw-card">
                <div class="index-data">
                    <div class="data-label">{{$t('今日个人收益')}} </div>
                    <div class="data-value"><span class="strong">{{splitNumber(user.money)[0]}}.{{splitNumber(user.money)[1]}}</span> <span >{{$t('USDT')}}</span></div>
                </div>
                <swiper :options="swiperMY" ref="my-swipe">
                    <template v-for="(item,index) in this.e_data">
                        <swiper-slide v-if="item.show_home" :key="index">
                            <div class="sw-data" >
                                <div class="col">
                                <div class="text"><img :src="item.logo" style="width: 16px"> {{item.name}}</div>
                                <div class="sw-val">{{item.close}}</div>
                                <div class="sw-per " :class="item.ratio > 0?'text-green':'text-red'">
                                    <template v-if="item.ratio > 0">
                                        +
                                    </template>
                                    {{item.ratio}}%
                                    <i class="iconfont " :class="item.ratio > 0?'icon-up':'icon-dn'"></i>
                                </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </template>
                </swiper>
            </div>
            <div class="sw-pdiv"><div class="sw-pagination" slot="pagination"></div></div>
        </div>
        <div class="index-first">
            <div class="index-ad" v-if="banner1" @click="enter">
                <template  >
                    <img :src="banner1.imgs" alt="">
                </template>
            </div>
            <div class="index-panel index-robot-panel no-banner">
                <div class="index-hd">{{$t('机器人')}}</div>
                <div class="has-robot ">
                    <span class="t">{{$t('我的机器人数量')}}：</span><span class="tag" @click="url('/robot')">{{parseInt(my_robot[0] + my_robot[1] + my_robot[2] + my_robot[3])}} {{$t('台')}}</span>
                </div>
<!--                <div class="type_main">-->
<!--                    <template v-for="item in typeOp">-->
<!--                        <div v-bind:key="item.val" @click="changeType(item.val)" :class="type_val == item.val ? 'type_active': '' " class="type_div">{{item.txt}}</div>-->
<!--                    </template>-->
<!--                </div>-->
                <div v-if="type_val == 0" class="robot-cells">
                    <ul class="robot-list">
                        <template v-for="(item,index) in robot" >
                        <li v-if="item.level != 0" :key="index" :class="setClass(index)">
                            <div class="robot-list-box" :class="item.check?'active':''">
                                <div class="robot-heading flex-center">
                                    <div class="robot-left">
                                        <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                                        <span class="tt">{{$t(item.name)}}</span>
                                    </div>
                                    <div class="robot-right">
                                        <van-button color="linear-gradient(to right, #fb5b5b, #ed4545)" @click="buyRobotDialog(item)" size="small">{{$t('签约购买')}}</van-button>
                                    </div>
                                </div>
                                <div class="robot-body">
                                    <div class="robot-item">
                                        <span class="iconfont icon-dollar_outlined"></span>
                                        <span>{{$t('价格')}}：</span><span
                                            class="text-red">{{item.price}}</span></div>
                                    <div class="robot-item">
                                        <span class="iconfont icon-time"></span><span>{{$t('有效期')}}：</span><span
                                            class="text-red">{{item.days}} {{$t('天')}}</span></div>
                                    <div class="robot-item">
                                        <span class="iconfont icon-zijin"></span><span>{{$t('做单价格')}}：</span><span
                                            class="text-red">{{parseFloat(item.min_money).toFixed(2)}}
                                            {{$t('元/单')}}</span></div>
                                </div>
                                <div class="roMore" @click="toggleA(index)">
                                    <!-- <van-icon :name="item.check?'arrow-up':'arrow-down'"/> -->
                                </div>
                                <!-- <div class="home-quo-item-bg-wapper" v-if="!item.check">
                                    <div class="home-quo-item-img-wapper">
                                        <img class="jiqiren-bg" src="../../assets/image/keji.gif" alt="">
                                    </div>
                                </div> -->
                            </div>
                        </li>
                        </template>
                    </ul>
                </div>
                <div v-if="type_val == 1" class="robot-cells">
                    <ul class="robot-list">
                        <template v-for="(item,index) in robot2" >
                        <li :key="index">
                            <div class="robot-list-box active">
                                <div class="robot-heading flex-center">
                                    <div class="robot-left">
                                        <img class="ro-logo" src="../../assets/pictures/avatar-default.png">
                                        <span class="tt">{{$t(item.t_name[0])}}</span>
                                    </div>
                                    <van-button type="warning" v-if="user.allow == 1" @click="buyRobotDialog2(item)">{{$t('签约')}}</van-button>
                                    <van-button type="warning" v-else @click="openService">{{$t('联系客服开通权限')}}</van-button>
                                </div>
                                <div class="robot-body">
                                    <div class="robot-item">
                                        <span class="iconfont icon-dollar_outlined"></span>
                                        <span>{{$t('运营资金')}}：</span>
                                        <span class="text-orange">{{item.t_min_money.join(', ')}}</span>
                                        <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                    </div>
                                    <div class="robot-item">
                                        <span class="iconfont icon-time"></span>
                                        <span>{{$t('有效期')}}：</span>
                                        <span class="text-orange">{{item.t_days.join(', ')}}</span>
                                        <span style="font-size: 12px"> {{$t('天')}}</span>
                                    </div>
                                    <div class="robot-item">
                                        <span class="iconfont icon-zijin"></span>
                                        <span>{{$t('预计日收益')}}：</span>
                                        <span class="text-orange">{{item.t_profit[0]}} ~ {{item.t_profit[item.t_profit.length -1]}}</span>
                                    </div>
                                    <div style="color: #999999;font-size: 12px">
                                        <span>{{$t(item.t_desc[0])}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="index-two">
            <div class="index-panel">
                <div class="index-hd">{{$t('服务Service')}}</div>
                <div class="serv-panel">
                    <van-row :gutter="10">
                        <van-col :span="12">
                            <router-link class="serv-card" :to="'/share?lang=' + lang">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/fenxiang.png" >
                                    <div class="text">{{$t('分享邀请')}}</div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
                            <router-link class="serv-card" to="/deposit">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/chongzhi.png" >
                                    <div class="text">{{$t('充值')}}</div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
                            <router-link class="serv-card" to="/withdraw">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/tixian.png" >
                                    <div class="text">{{$t('提现')}}</div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
<!--                            <router-link to="/customer-serv">-->
                            <a class="serv-card" @click="openService">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/jiqiren.png" >
                                    <div class="text">{{$t('网上客服')}}</div>
                                </div>
                            </a>
<!--                            </router-link>-->
                        </van-col>
                        <van-col :span="12">
                            <router-link class="serv-card" to="/help">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/book.png" >
                                    <div class="text">
                                        {{$t('帮助指南')}}
                                    </div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
                            <router-link class="serv-card" to="/about">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/warning.png" >
                                    <div class="text">
                                        {{$t('关于我们')}}
                                    </div>
                                </div>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="index-three">
            <div class="index-panel">
                <div class="index-hd" >{{$t('合作伙伴')}}</div>
                <div class="pSwiper">
                    <div class="col" v-for="(item, index) in exchange" :key="index">
                        <div class="par-box">
                            <img :src="item.logo">
                        </div>
                    </div>
                    <!-- <swiper :options="swiperParter" ref="pSwiper">
                        <swiper-slide v-for="(item, index) in exchange" :key="index">
                            
                        </swiper-slide>
                    </swiper> -->
                </div>
            </div>
        </div>
        <Foot :active="0"/>
        <van-popup class="bs-popup" v-model="notice_alert.visible" position="center" closeable 
                   style="background-color:unset"
                   :style="{ borderRadius: '10px', width: notice_alert.type == '1' ? '80%': 'unset'}">
            
            <div class="content" v-html="notice_alert.text" v-if="notice_alert.type == 1"></div>
            <div class="notice-dialog" v-else >
                <div class="popup-notice">
                    <div class="wt-top"><div v-if="notice_alert.type != '1'" class="animated bounceInUp del3" style="width: 26px; margin-right: 5px;"></div>{{$t('Notice')}}</div>
                    <div class="notic-body">
                        <div class="cont" v-html="notice_alert.text">
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup class="bs-popup " v-model="buyRobotShow" position="bottom" closeable>
            <div class="bs-popup-dialog" style="padding:20px 30px">
                <div class="bs-popup-head">
                    <div class="tt">{{$t('签约购买')}}</div>
                </div>
                <div class="dialog-v-body" >
                    <div class="bs-panel dv-card">
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label text-gray">{{$t('每月')}}</div>
                                <div class="val">{{buyRobotShowInfo.price}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                            </div>
                            <div class="dv-data" style="text-align: right">
                                <div class="label text-gray">{{$t('预计每日收益')}}</div>
                                <div class="val">{{parseFloat(buyRobotShowInfo.profit).toFixed(2)}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label text-gray">{{$t('有效期')}}</div>
                                <div class="val">{{buyRobotShowInfo.days}} {{$t('天')}}</div>
                            </div>
                            <div class="dv-data" style="text-align: right">
                                <div class="label text-gray">{{$t('做单价格')}}</div>
                                <div class="val">
                                    {{parseFloat(buyRobotShowInfo.min_money).toFixed(2)}}
                                    {{$t('元/单')}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bs-panel dv-card">
                        <div class="dv-less flex-center">
                            <div class="flex_bd">
                                <div class="label text-gray">{{$t('当前余额')}}</div>
                                <div class="value">{{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                            </div>
                            <div class="dv-input-row ">
                                <van-field
                                        v-model="num"
                                >
                                    <template #input>
                                        <div style="text-align: center;width: 100%" class="h-stp">
                                            <van-stepper :disabled="numDisabled" v-model="num" disable-input  center @change="changeNum"
                                                         clearable/>
                                        </div>
                                    </template>
                                </van-field>
                            </div>
                        </div>
                        <div class="dv-foot">
                            <van-button color="linear-gradient(to right, #3270e0, #3653ea)"  @click="buyRobot">{{$t('支付')}} {{parseFloat(pay_money).toFixed(2)}} {{$t('USDT')}}</van-button>
                        </div>
                    </div>
                    <div class="nt-tip">
                        <div class="hd text-red">{{$t('温馨提示')}}：</div>
                        <div class="bd text-gray">
                            <p v-if="buyRobotShowInfo.desc">{{$t(buyRobotShowInfo.desc)}}</p>
                            <p>{{$t('机器人可购买，买卖，转让。')}}</p>
                            <p>{{$t('每个等级机器人，同时可运行一个，请保障帐户操作本金充足。')}}</p>
                            <p>{{$t('机器人具有时效性，请充分运行机器人，赚取更多收益。')}}</p>
                            <p>{{$t('机器人级别越高，做单时间更有效率，更能捕捉收益。')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="bs-popup " v-model="buyRobotShow2" position="bottom" closeable>
            <div class="robot-top animated bounceInUp del3"><img src="../../assets/pictures/avatar-default.png" alt=""></div>
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">
                    <div class="tt">{{$t('签约')}}</div>
                </div>
                <div class="dialog-v-body">
                    <div class="bs-panel dv-card">
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('投入金额')}}
                            </div>
                            <div class="dv-t-main">
                            <template v-for="(item, index) in item1">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[0] == item ? 'dv-active' : ''" @click="change2(0, parseFloat(item))">{{parseFloat(item)}}</div>
                                </div>
                            </template>
                            </div>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('周期')}}
                            </div>
                            <template v-for="(item, index) in item2">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[1] == item ? 'dv-active' : ''" @click="change2(1, item)">{{item}}</div>
                                </div>
                            </template>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('模式')}}
                            </div>
                            <template v-for="(item, index) in item3">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[2] == item ? 'dv-active' : ''" @click="change2(2, item)">{{item ? $t('不可赎回'):$t('可赎回')}}</div>
                                </div>
                            </template>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('预估日收益')}}
                            </div>
                            <div class="dv-t" >
                                <div class="dv-item dv-active">{{parseFloat(item4.profit ? item4.profit: 0) }} %</div>
                            </div>
                        </div>
                    </div>
                    <div class="bs-panel dv-card">
                        <div class="dv-less">
                            <div class="label text-gray">{{$t('当前余额')}}</div>
                            <div class="value">{{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                        </div>
                        <div class="dv-foot">
                            <van-button type="warning"  @click="buyRobot2(item4.id)" :disabled="item4.id > 0 ? false: true">{{$t('支付')}} {{item4.min_money ? parseFloat(item4.min_money) : '?' }} {{$t('USDT')}}</van-button>
                        </div>
                    </div>
                    <div class="nt-tip">
                        <div class="hd">{{$t('说明')}}：</div>
                        <div class="bd">
                            <p v-if="buyRobotShowInfo2.t_desc">{{$t(buyRobotShowInfo2.t_desc[0])}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="index-popup-ad" style="background-color: unset;" v-model="show" position="center" closeable >
            <div class="popup-ad">
                <div class="popup-ad-grid">
                    <div class="lg"><span>0</span>{{$t('投入')}}</div>
                    <div class="md">{{$t('首次投资')}}</div>
                </div>
            </div>
            <div class="popup-ad-ft">
                <van-button type="warning" @click="firstInvestmentShowf2">{{$t('开启首次投资')}}</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";
    import helper from "../../utils/helper";
    import { Toast } from 'vant';
    export default {
        components: {
            Foot
        },
        data() {
            return {
                select_lang:false,
                typeOp:[
                    {txt: this.$t('普通机器人'), val: 0},
                    // {txt: this.$t('保全理财机器人'), val: 1},
                ],
                type_val: 0,
                show: false,
                show2: true,
                name_logo: '',
                loading: true,
                num: 1,
                numDisabled: false,
                pay_money: '',
                user: {name: '', money: 0.00, balance: 0.00},
                firstInvestmentShow: false,
                firstInvestmentInfo: [],
                buyRobotShow: false,
                buyRobotShow2: false,
                buyRobotShowInfo: [],
                buyRobotShowInfo2: [],
                notices: '',
                notice_alert: {
                    text: "",
                    visible: false,
                    id: "",
                    type: '',
                },
                e_data: [],
                robot: [],
                robot2: [],
                my_robot: [0, 0, 0, 0],
                banner0: '',
                banner1: '',
                banner2: '',
                b_timer: null,
                b_load: false,
                m_count: '',
                swipe_index: 0,
                swipe_c: 0,
                swiperParter: {
                    observer: true,
                    observeParents: true,
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                },
                swiperMY: {
                    observer: true,
                    observeParents: true,
                    slidesPerView: '3',
                    // centeredSlides: true,
                    spaceBetween: 10,
                    // loop:true,
                    // autoplay: {
                    //     delay: 4500,
                    //     disableOnInteraction: false,
                    // },
                    pagination: {
                        el: '.sw-pagination'
                    },
                },
                exchange: [],
                agent_list: [],
                item_active: [-1, -1, -1, -1],
                item1:[],
                item2:[],
                item3:[],
                item4:[],
                lang:''
            }
        },
        methods: {
            getRobotConfig(level, type){
                let that = this;
                this.$axios
                    .post(this.$api_url.get_robot_config, this.$qs.stringify({l: level, type: type, c: this.item_active}))
                    .then((res) => {
                        if(res.data.code == 0){
                            if(type == 1){
                                that.item1 = res.data.data;
                            }
                            if(type == 2){
                                that.item2 = res.data.data;
                            }
                            if(type == 3){
                                that.item3 = res.data.data;
                            }
                            if(type == 4){
                                that.item4 = res.data.data;
                            }
                        }
                    });
            },
            change2(i, index){
                this.item_active.splice(i, 1, index);
                for(let ii = i + 1; ii < this.item_active.length; ii++){
                    this.item_active.splice(ii, 1, -1);
                }

                if(i == 0){
                    this.item4 = [];
                    this.item3 = [];
                    this.item2 = [];
                }
                if(i == 1){
                    this.item4 = [];
                    this.item3 = [];
                }
                if(i == 2){
                    this.item4 = [];
                }

                console.log(this.item_active);
                this.getRobotConfig(this.buyRobotShowInfo2.info[0].level, i + 2);
            },
            changeType(val){
                this.type_val = val;
            },
            openService(){
                window._vue.openService();
            },
            toggleA(index) {
                var check = this.robot[index].check;
                this.robot[index].check = check === true ? false : true;
                this.$forceUpdate()
            },
            setClass(value) {
                let obj = {face: true}
                obj["ro-style-" + `${value % 4}`] = true
                return obj
            },
            onChange(index) {
                this.swipe_index = index;
            },
            changeNum() {
                this.pay_money = this.buyRobotShowInfo.price * this.num;
            },
            firstInvestmentShowf() {
                this.buyRobotShowInfo = this.robot.filter(item => item.level == 0).pop();
                this.buyRobotShow = true;
                this.num = 1;
                this.changeNum();
                this.numDisabled = true;
            },
            firstInvestmentShowf2(){
                this.show = false;
                this.firstInvestmentShowf();
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            getData() {
                this.$axios
                    .get(this.$api_url.get_home_data)
                    .then((res) => {
                            if (res.data && res.data.code == 0) {
                                this.loading = false;
                                this.notices = res.data.data.notices;
                                this.robot = res.data.data.robot;
                                this.robot2 = res.data.data.robot2;
                                this.robot[0].check = true;
                                // this.partner = res.data.data.b_partner;
                                this.exchange = res.data.data.b_exchange;
                                this.e_data = res.data.data.e_data;
                                this.banner0 = res.data.data.banner0;
                                this.swipe_c = 1 + (this.banner0 ? this.banner0.length : 0);
                                this.banner1 = res.data.data.banner1;
                                this.banner2 = res.data.data.banner2;
                                this.my_robot = res.data.data.my_robot;
                                // this.show = res.data.data.free_robot > 0 ? false : true;
                                this.m_count = res.data.data.m_count;
                                this.user = res.data.data.user;
                                localStorage.setItem('allUser', JSON.stringify(this.user));
                                // this.$refs.mySwiper.swiper.slideTo(this.user.level);
                                this.agent_list = res.data.data.agent_list;

                                if (res.data.data.notice_alert != undefined) {
                                    this.notice_alert.text = res.data.data.notice_alert.title;
                                    this.notice_alert.id = res.data.data.notice_alert.id;
                                    this.notice_alert.type = res.data.data.notice_alert.type;
                                    let old_id = sessionStorage.getItem("notice_id");
                                    if (old_id != this.notice_alert.id) {
                                        this.notice_alert.visible = true;
                                        sessionStorage.setItem("notice_id", this.notice_alert.id);
                                    }
                                }
                            }
                        }
                    )
                    .catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            },
            getB() {
                if (this.b_load) {
                    return;
                }
                this.b_load = true;
                this.$axios.get(this.$api_url.get_b).then((res) => {
                    this.e_data = res.data;
                    this.b_load = false;
                });
            },
            buyRobot() {
                this.$axios
                    .post('/robot/buy_robot', {id: this.buyRobotShowInfo.id, num: this.num})
                    .then((res) => {
                        let result = res.data;
                        if (result.code === 0) {
                            this.buyRobotShow = false;
                            this.$toast.success(result.msg);
                            // this.getData();
                            this.url('/robot');
                        } else {
                            this.$toast.fail(result.msg);
                        }
                    });
            },
            buyRobot2(id) {
                this.$axios
                    .post('/robot/buy_robot2', {id: id})
                    .then((res) => {
                        let result = res.data;
                        if (result.code === 0) {
                            this.buyRobotShow2 = false;
                            this.$toast.success(result.msg);
                            // this.getData();
                            this.url('/robot');
                        } else {
                            this.$toast.fail(result.msg);
                        }
                    });
            },
            buyRobotDialog(item) {

                if(item.level == 0){
                    return this.firstInvestmentShowf();
                }
                this.buyRobotShow = true;
                this.buyRobotShowInfo = item;
                this.num = 1;
                this.changeNum();
                this.numDisabled = false;
            },
            buyRobotDialog2(item) {
                console.log(item);
                this.buyRobotShow2 = true;
                this.buyRobotShowInfo2 = item;
                this.getRobotConfig(item.info[0].level, 1);
            },
            async checkLogin() {
                await this.$axios.get(this.$api_url.v_login)
                    .catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            },
            enter(){
                this.url('/christmas');
                // this.$toast.fail(this.$t('敬请期待'));
            },
        },
        mounted() {
            this.getData();
            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.lang = helper.getLang() || 'false';
            this.b_timer = setInterval(() => {
                this.getB()
            }, 1000);
        },
        destroyed() {
            clearInterval(this.b_timer);
        },
        created() {
            this.name_logo = localStorage.getItem('app.title') || this.$t('Vault');
            this.checkLogin();
        },
    }
</script>
<style scoped>
    .index-top{
        position: relative;
        z-index: 9;
        padding: 60px 20px 20px 20px;
        border-radius: 0 0 30px 30px;
        background: url(../../assets/pictures/indextopbg.jpg) no-repeat;
        background-size: cover;
        border-radius: 0 0 30px 30px;
    }
    .index-three{
        position: relative;
        z-index: 2;
        padding: 40px 20px 20px 20px;
        border-radius: 0 0 30px 30px;
        background: url(../../assets/pictures/hzbg.jpg) no-repeat;
        background-size: cover;
        margin-top: -20px;
    }
    .index-two{
        position: relative;
        z-index: 3;
        padding: 40px 20px 20px 20px;
        border-radius: 0 0 30px 30px;
        background-color: #11181d;
        margin-top: -20px;
    }
    .index-first{
        position: relative;
        z-index: 6;
        padding: 40px 20px 20px 20px;
        border-radius: 0 0 30px 30px;
        background-color: #1a2331;
        margin-top: -20px;
    }
    .pSwiper{
        margin:0 -6px;
        display: flex;
        flex-wrap: wrap;
    }
    .pSwiper .col{
        padding: 6px;
        width: 33.33%;
    }



</style>