import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/trend',
		name: 'trend',
		component: () => import('../views/index/robot-run-page.vue')
	},
	{
		path: '/welcome',
		name: 'welcome',
		component: () => import('../views/welcome.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/regist',
		name: 'regist',
		component: () => import('../views/regist.vue')
	},
	{
		path: '/find-pwd',
		name: 'find-pwd',
		component: () => import('../views/find-pwd.vue')
	},
	{
		path: '/my',
		name: 'my',
		component: () => import('../views/ucenter/Index.vue')
	},
	{
		path: '/team-member',
		name: 'team-member',
		component: () => import('../views/ucenter/tm-use-info.vue')
	},
	{
		path: '/team',
		name: 'team',
		component: () => import('../views/ucenter/uc-team')
	},
	{
		path: '/tm-rule',
		name: 'tm-rule',
		component: () => import('../views/ucenter/tm-rule')
	},
	{
		path: '/bank',
		name: 'bank',
		component: () => import('../views/ucenter/bank.vue')
	},
	{
		path: '/bonus',
		name: 'bonus',
		component: () => import('../views/ucenter/agent-page.vue')
	},
	{
		path: '/withdraw-record',
		name: 'withdraw-record',
		component: () => import('../views/ucenter/withdraw-record.vue')
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		component: () => import('../views/ucenter/withdrawal-style.vue')
	},
	{
		path: '/deposit-record',
		name: 'deposit-record',
		component: () => import('../views/ucenter/cunk-record.vue')
	},
	{
		path: '/bank-bind',
		name: 'bank-bind',
		component: () => import('../views/ucenter/bank-bind.vue')
	},
	{
		path: '/login-pwd',
		name: 'login-pwd',
		component: () => import('../views/ucenter/login-pwd.vue')
	},
	{
		path: '/trade-record',
		name: 'trade-record',
		component: () => import('../views/ucenter/trade-record.vue')
	},
	{
		path: '/trade-detail',
		name: 'trade-detail',
		component: () => import('../views/ucenter/trade-detail.vue')
	},
	{
		path: '/deposit',
		name: 'deposit',
		component: () => import('../views/ucenter/user-deposit.vue')
	},
	{
		path: '/customer-serv',
		name: 'customer-serv',
		component: () => import('../views/ucenter/customer-serv.vue')
	},
	{
		path: '/share',
		name: 'share',
		component: () => import('../views/team/Index.vue')
	},
	{
		path: '/proxy',
		name: 'proxy',
		component: () => import('../views/team/proxy.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/page/about.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('../views/page/help.vue')
	},
	{
		path: '/robot',
		name: 'robot',
		component: () => import('../views/ucenter/robot-list.vue')
	},
	{
		path: '/cost',
		name: 'cost',
		component: () => import('../views/ucenter/cost-record.vue')
	},
	{
		path: '/income',
		name: 'income',
		component: () => import('../views/ucenter/income-record.vue')
	},
	{
		path: '/message-list',
		name: 'message-list',
		component: () => import('../views/index/message-list.vue')
	},
	{
		path: '/message-detail',
		name: 'message-detail',
		component: () => import('../views/index/message-detail.vue')
	},
	{
		path: '/lang',
		name: 'lang',
		component: () => import('../views/page/lang.vue')
	},
	{
		path: '/recharge',
		name: 'recharge',
		component: () => import('../views/page/recharge.vue')
	},
	{
		path: '/bind-wallet',
		name: 'bind-wallet',
		component: () => import('../views/ucenter/withdrawal-page.vue')
	},
	{
		path: '/team-commission',
		name: 'team-commission',
		component: () => import('../views/ucenter/uc-team-commission.vue')
	},
	{
		path: '/robot-list',
		name: 'robot-list',
		component: () => import('../views/index/robot-list.vue')
	},
	{
		path: '/robot-no',
		name: 'robot-no',
		component: () => import('../views/index/robot-no.vue')
	},
	{
		path: '/pix-bind',
		name: 'pix-bind',
		component: () => import('../views/ucenter/pix-bind')
	},
	{
		path: '/re-page',
		name: 're-page',
		component: () => import('../views/page/re-page')
	},
	{
		path: '/re-error',
		name: 're-error',
		component: () => import('../views/page/re-error')
	},
	{
		path: '/pay-set',
		name: 'pay-set',
		component: () => import('../views/ucenter/pay-set.vue')
	},
	{
		path: '/code-set',
		name: 'code-set',
		component: () => import('../views/ucenter/code-set')
	},
	{
		path: '/code-set-one',
		name: 'code-set-one',
		component: () => import('../views/ucenter/code-set-one')
	},
	{
		path: '/code-set-two',
		name: 'code-set-two',
		component: () => import('../views/ucenter/code-set-two')
	},
	{
		path: '/code-set-unbind',
		name: 'code-set-unbind',
		component: () => import('../views/ucenter/code-set-unbind')
	},
	{
		path: '/email-set',
		name: 'email-set',
		component: () => import('../views/ucenter/email-set')
	},
	{
		path: '/christmas',
		name: 'christmas',
		component: () => import('../views/page/christmas')
	},
]

const router = new VueRouter({
	routes
})

export default router
