<template>
    <div>
        <vue-draggable-resizable
                v-if="whs.length > 0"
                :w="64" :h="64"
                :resizable="false"
                class-name="whs-service">
            <div class="whs-service-btn" @click="openService">
                <img src="../assets/pictures/aside-kefu.png" alt="">
            </div>
        </vue-draggable-resizable>
        <van-popup
                v-model="show"
                class="serve-popup"
                position="bottom"
        >
            <van-cell-group :title="$t('在线客服')">
                <van-cell
                        v-for="(item,i) in whs"
                        :key="`whs${i}`"
                        is-link
                        :title="item.label"
                        center
                        icon="chat-o"
                    >
                    <template #icon>
                        <template >
                            <img src="../assets/pictures/kefu.png" width="30" height="30" style="margin-right: 5px"/>
                        </template>
                    </template>
                    <template #title>
                        <a :href="item.url" target="_blank">
                            <div v-if="i == 0" style="font-weight:bold;width: 100%">
                                {{item.label}}
                            </div>
                            <div v-else style="width: 100%">{{item.label}}</div>
                        </a>
                    </template>
                    <template #right-icon>
                        <template v-if="item.tag == 'copy'">
                            <van-button style="height: 22px;max-width: 88px"
                                        :class="'btn-copy copy_' + $md5.update(item.url)"
                                        :data-clipboard-text="item.url"
                                        @click="copy('.copy_' + $md5.update(item.url))"
                            >{{$t('copy')}}
                            </van-button>
                        </template>
                        <template v-else>
                            <a :href="item.url" target="_blank" >
                                <van-icon name="arrow"/>
                            </a>
                        </template>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
    import VueDraggableResizable from 'vue-draggable-resizable';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
    import Clipboard from "clipboard";
    export default {
        name: "Service",
        components: {VueDraggableResizable},
        data() {
            return {
                whs: [],
                show: false,
            };
        },
        methods: {
            openService() {
                this.show = true;
            },
            getWhs() {
                let whs = localStorage.getItem("app.whatsapp_service");
                console.log(whs, 'service');
                if (whs === null) {
                    setTimeout(() => {
                        this.getWhs()
                    }, 300);
                    return;
                }
                if (whs) {
                    this.whs = JSON.parse(whs);
                }
            },
            copy(val) {
                let clipboard = new Clipboard(val);
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                    console.log(this.share_url)
                });
                clipboard.on("error", (e) => {
                    // 不支持复制
                    this.$toast.fail(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getWhs()
            });
            let that = this;
            window._vue.$on('getWhs', () => {
                that.getWhs();
            })
            window._vue.openService = this.openService;
        }
    };
</script>
<style scoped>
    .serve-popup ::v-deep {
        padding:15px 20px;
        border-radius: 20px 20px 0 0;
        background-color: #11161b;
    }
    .serve-popup ::v-deep .van-cell-group__title{
        text-align: center;
        color: #FFFFFF;
        font-size: 18px;
    }


    .whs-service {
        border: 0;
        position: fixed;
        bottom: 10%;
        right: 5%;
        touch-action: none;
        z-index: 9999 !important;
    }

    /*.whs-service-btn {*/
    /*    background: #408bf9;*/
    /*    width: 64px;*/
    /*    height: 64px;*/
    /*    border-radius: 50%;*/
    /*    line-height: 64px;*/
    /*    text-align: center;*/
    /*    padding-top: 10px;*/
    /*    color: #FFF;*/
    /*}*/

    .whs-service-btn{
        cursor: pointer;
        position: fixed;
        z-index: 9999;
        right: 15px;
        bottom: 12%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        border-radius: 430px;
    }

    /*.van-cell__title {*/
    /*    color: #FFFFFF;*/
    /*}*/

    /*.van-cell__left-icon {*/
    /*    color: #FFFFFF;*/
    /*}*/
</style>