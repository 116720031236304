export default {
    v_login: '/v_login', //验证是否登录
    login: "/login", //登录
    register: "/register", //注册
    send_code: "/send_code", //发送短信
    get_captcha: "/get_captcha", //获取验证码
    forget: "/forget", //找回密码
    get_config: "/config", //网站配置
    user: '', //获取用户信息
    get_home_data: "/getIndexData", //获取首页相关数据
    get_b: "/get_b", //获取首页相关数据
    recharge: "/member/recharge", //充值
    get_recharge_bank_info: "/member/get_recharge_bank_info", //获取银行卡充值信息
    get_recharge_scene: "/bank/get_scene", //获取充值渠道列表
    get_day_report: "/report/day_report", //获取日报表信息
    cost_list: "/account/cost_list", //获取支出记录
    income_list: "/account/income_list", //获取收入记录
    recharge_list: "/account/recharge_list", //获取充值记录
    bind_account: "/account/bind", //绑定账号
    unbind_account: "/account/unbind", //解绑账号
    bind_refresh: "/account/bind_refresh",
    bind_record: "/account/bind_record",
    distributions: "/user/distributions", //团队报表
    get_team_user: "/user/get_team_user", //团队报表
    update_pwd: "/user/update_pwd", //修改登录密码
    save_pay_password: "/user/save_pay_password", //修改交易密码
    save_more_info: "/user/save_more_info", //保存用户更多信息
    save_headphoto: "/user/save_headphoto", //保存用户头像
    order_add: "/order/add", //领取订单
    order_list: "/order/list", //订单记录
    order_stat: "/order/stat", //订单统计
    order_info: "/order/:id", //订单详情
    confirm_order_content: "/order/confirm_content",
    order_clicked: "/order/clicked", //增加点击
    throw_order: "/order/throw_order", //删除订单
    get_help: "/help/get_list", //获取帮助列表
    get_banner: "/banner/get_list", //获取banner
    get_share: "/share/get_row", //获取分享海报
    get_bind_bank: "/bank/get_bank", //获取绑定的银行信息
    save_bind_bank: "/bank/save", //绑定银行卡
    withdraw_list: "/withdraw/get_list", //获取提现记录
    withdraw_add: "/withdraw/add", //提交提现申请
    withdraw_info: "/withdraw/get_withdraw_info", //获取提现相关提示信息
    kf_send: "/kf/send", //发送客服消息
    kf_get_list: "/kf/get_list", //获取客服消息列表
    kf_get_msg: "/kf/get_msg", //获取客服消息
    codeimg: "/codeimg", //验证码图片
    get_meiqia: "/get_meiqia", //获取美洽客服id
    get_langs: "/langs", //获取语言列表
    set_lang: "/change_lang", //设置语言
    get_i18n_messages: "/langs/messages", //获取语言文本信息
    get_lines: "/lines", //获取线路
    get_g_code: '/gcode/get_code',
    set_code: '/gcode/set_code',
    get_robot_config: '/robot/r_config',
}
